import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styles from './ArchonLayout.module.scss';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  ThemeContext,
  ThemeContextProvider,
  V3Theme,
} from '../../../resources/js/contexts/useTheme';
import ArchonHeader, {
  ArchonHeaderViewModel,
  BreadcrumbViewModel,
} from '../../components/ArchonHeader/ArchonHeader';
import ArchonFooter, {
  ArchonFooterViewModel,
} from '../../components/ArchonFooter/ArchonFooter';
import LazyLoad from 'react-lazyload';
import { ASSET_SERVER_BASE_URL } from '../../../resources/js/constants/assetServer';
import Image from 'next/legacy/image';
import RouteChangeIndicator from '../../components/RouteChangeIndicator/RouteChangeIndicator';
import cx from 'classnames';
import renderEnhancedMarkdownToString from '@resources/js/components/EnhancedMarkdown/helpers/renderEnhancedMarkdownToString';
import useGame from '@resources/js/contexts/useGame';
import Advertisements from '../../components/Advertisements/Advertisements';
import ArchonModal from '@resources/js/components/ArchonModal/ArchonModal';
import EnhancedMarkdown from '@resources/js/components/EnhancedMarkdown/EnhancedMarkdown';
import useLocalStorage from '@resources/js/hooks/useLocalStorage';
import { ArchonAdPlacement } from '../../components/ArchonAdPlacement/ArchonAdPlacement';
import { ArchonAdPlacementWithCloseButton } from '../../components/ArchonAdPlacementWithCloseButton/ArchonAdPlacementWithCloseButton';
import { SpeedInsights } from '@vercel/speed-insights/next';
import useShowAdContainers from '../../hooks/useShowAdContainers';
import Script from 'next/script';

type LayoutProps = {
  children: ReactNode;
  header?: ArchonHeaderViewModel;
  breadcrumbs?: BreadcrumbViewModel[];
  theme?: ThemeContext;
  footer?: ArchonFooterViewModel;
};

const baseUrl = 'https://www.archon.gg';

function ArchonLayout({
  children,
  header,
  breadcrumbs: propBreadcrumbs = [],
  theme = V3Theme,
  footer,
}: LayoutProps): JSX.Element {
  const game = useGame();
  const router = useRouter();
  const logoUrl = ASSET_SERVER_BASE_URL + '/img/archon/logo.svg';
  const showAds = useShowAdContainers();
  const [
    subcreationMigrationNoticeDismissed,
    setSubcreationMigrationNoticeDismissed,
  ] = useLocalStorage('subcreationMigrationNoticeDismissed', false);
  const showSubcreationModal =
    router.query.utm_campaign === 'subcreation-migration' &&
    router.query.utm_medium === 'redirect' &&
    !subcreationMigrationNoticeDismissed;

  const [breadcrumbs, setBreadcrumbs] = useState(
    propBreadcrumbs.length ? propBreadcrumbs : header?.defaultBreadcrumbs ?? []
  );
  useEffect(() => {
    if (propBreadcrumbs.length === 0) return;

    setBreadcrumbs(
      propBreadcrumbs.map((breadcrumb) => ({
        ...breadcrumb,
        label: renderEnhancedMarkdownToString(breadcrumb.label, game),
      }))
    );
  }, [propBreadcrumbs, game]);

  return (
    <ThemeContextProvider theme={theme ?? V3Theme}>
      <Head>
        <meta property='og:title' key='og:title' content={'Archon'} />
        <link rel='icon' type='image/svg' href={logoUrl} />
        <link
          rel='canonical'
          href={`${baseUrl}${router.asPath.split('#')[0]}`}
        />
        <meta property='og:site_name' key='og:site_name' content={'Archon'} />
        <meta property='og:type' key='og:type' content='website' />
        <meta key='og:image' property='og:image' content={logoUrl} />
        <meta
          property='og:url'
          content={`${baseUrl}${router.asPath.split('#')[0]}`}
        />
        <script type='application/ld+json'>
          {getBreadcrumbLdJson(breadcrumbs)}
        </script>
      </Head>
      {/** Microsoft Clarity analytics **/}
      <Script id='clarity-script' strategy='afterInteractive'>
        {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "ocwmr93el3");
          `}
      </Script>
      <div className={styles.layout}>
        <RouteChangeIndicator />
        {header && (
          <div className={styles.header}>
            <ArchonHeader {...header} breadcrumbs={breadcrumbs} />
          </div>
        )}
        <main
          className={cx({
            [styles.main]: true,
            [styles.mainWithBreadcrumbs]: breadcrumbs.length > 0,
          })}
        >
          {showSubcreationModal ? (
            <ArchonModal
              content={
                <EnhancedMarkdown
                  markdown={`
# Subcreation Has Migrated to Archon!

Subcreation has been working with Archon to ensure that the builds and tier lists you're looking for match the high quality you have come to expect from Subcreation.

<a href="https://subcreation.net/" target="_blank">Read Subcreation&apos;s letter about the migration.</a>
`}
                />
              }
              buttonLabel={'Got It!'}
              buttonOnClick={() => setSubcreationMigrationNoticeDismissed(true)}
            />
          ) : null}
          {children}
        </main>
        {footer && (
          <div
            className={cx({
              [styles.footer]: true,
              [styles.footerWithAds]: showAds,
            })}
          >
            <LazyLoad height={220}>
              <ArchonFooter {...footer} />
            </LazyLoad>
          </div>
        )}
      </div>
      <SpeedInsights sampleRate={0.005} />
    </ThemeContextProvider>
  );
}

export function Container({
  children,
  style,
  className,
  shouldShowAdsIfEnabled = false,
}: {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  shouldShowAdsIfEnabled?: boolean;
}): JSX.Element {
  const showAdContainers = useShowAdContainers();

  const showAds = shouldShowAdsIfEnabled && showAdContainers;

  return (
    <div
      className={cx({
        [styles.container]: true,
        [styles.containerWithAds]: showAds,
        [className || '']: Boolean(className),
      })}
      style={style}
    >
      {children}
      {showAds && (
        <div className={styles.containerSidebar}>
          <Advertisements className={styles.containerSidebarSticky}>
            <ArchonAdPlacement location={'sideRailWide'} />
          </Advertisements>
        </div>
      )}
      {showAds && (
        <span className={styles.stickyFooterAd}>
          <ArchonAdPlacementWithCloseButton>
            <ArchonAdPlacement location={'stickyFooter'} />
          </ArchonAdPlacementWithCloseButton>
        </span>
      )}
    </div>
  );
}

export function Background(): JSX.Element {
  return (
    <div className={styles.backgroundImageContainer}>
      <Image
        className={styles.backgroundImage}
        src={
          ASSET_SERVER_BASE_URL + '/img/warcraft/desktop-client-background.jpg'
        }
        alt={''}
        layout={'fill'}
        quality={1}
        placeholder={'blur'}
        blurDataURL={
          'data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAACAAQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAf/xAAeEAABBAEFAAAAAAAAAAAAAAACAAEDBAcFBhEiQf/EABQBAQAAAAAAAAAAAAAAAAAAAAX/xAAYEQADAQEAAAAAAAAAAAAAAAAAAQMyM//aAAwDAQACEQMRAD8An+XdSvVMp7phq3bUMQ3X4COUhFug+M6IiPt0Y9LCP//Z'
        }
      />
      <div className={styles.backgroundImageOverlay} />
    </div>
  );
}

function getBreadcrumbLdJson(breadcrumbs: BreadcrumbViewModel[]): string {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb.label,
      item: baseUrl + breadcrumb.url,
    })),
  });
}

export default ArchonLayout;
